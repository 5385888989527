details {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

summary {
  font-weight: bold;
  cursor: pointer;
}

.btn-calculator {
  background-color: black !important;
  color: white !important;
}

.jobdone-calculator {
  font-family: sans-serif;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 30px;
  background-color: black;
}

.job-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.job-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.job-item {
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.job-id {
  font-weight: bold;
  margin-right: 5px;
}

.job-price {
  font-weight: bold;
}

.total-earnings {
  font-weight: bold;
  color: #0a7baf;
}

.enrolled-outline {
  display: flex;
  flex-direction: row;
  /* position: relative; */
  height: 100vh; /* set height of the container to viewport height */
}

.enrolled-outline__outline {
  position: sticky;
  top: 0;
  flex-basis: 30%;
  height: 100%;
  overflow-y: scroll; /* add scroll only to left side */
  background-color: #f8f9fa;
  padding: 15px;
}

.enrolled-outline__content {
  position: absolute;
  top: 0;
  flex-grow: 1;
  left: 30%; /* set left side width here */
  right: 0;
  bottom: 0;
  /*overflow: hidden;*/ /* hide overflow */
  background-color: #fff;
  padding: 15px;
}

/* Add this media query to adjust the layout for smaller screens */
@media (max-width: 768px) {
  .courseInfoTabs {
    display: none;
  }

  .enrolled-outline {
    display: flex;
    flex-direction: column;
    height: auto; /* Remove fixed height for responsiveness */
  }

  .enrolled-outline__content {
    order: 1; /* Set the order of content to 1 to move it to the top */
    flex-grow: 1; /* Allow content to fill the available space */
    width: 100%; /* Set width to 100% to fill the available space */
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px; /* Add some spacing between content and outline */
    /* Disable position and top properties */
    position: static;
    top: auto;
  }

  .enrolled-outline__outline {
    order: 2; /* Set the order of outline to 2 to move it below content */
    flex-basis: unset; /* Reset the width of the outline container */
    height: 100%;
    overflow-y: auto; /* Use "auto" instead of "scroll" to only show the scrollbar when necessary */
    background-color: #f8f9fa;
    padding: 15px;
    /* Disable position and top properties */
    position: static;
    top: auto;
  }
}

.nowa-sections {
  min-height: 125px;
  display: flex;
  align-items: center;
  border: 1px #eee solid;
  padding: 10px 0;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  margin: 25px 0;
}
.nowa-section {
  background-color: #ddd;
  padding: 5px;
  margin: 5px 0;
  width: 100%;
}
.nav-right {
  align-items: center;
}
.welcome-name {
  color: #fff;
}
